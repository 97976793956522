.exercise-process-item {
    min-width: 30px;
    width: 30px;
    max-width: 30px;
    height: 30px;
    border: 1px solid var(--ion-color-light);
    font-size: 15px;
    line-height: 30px;
    text-align: center;
}

.primary {
    border: var(--ion-color-primary);
    background: var(--ion-color-primary);
    color: white;
}
.active {
    border: 1px solid #1179c8;
    background: #1179c8;
    color: white;
}
.valid {
    background: #50b85b;
    border: 1px solid #50b85b;
    color: var(--ion-color-secondary-contrast);
}

.none {
    border: 1px solid var(--ion-color-medium);
    background: var(--ion-color-medium);
    color: var(--ion-color-primary-contrast);
}

.canceled {
    border: 1px solid var(--ion-color-danger);
    background: var(--ion-color-danger);
    color: var(--ion-color-danger-contrast);
}
