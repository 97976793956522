dp-day-calendar {
    width: 100% !important;
}

dp-day-calendar .dp-calendar-weekday {
    height: 25px;
    width: 41px !important;
    line-height: 25px;
    color: #7a7a7a;
    border: none;
}

dp-day-calendar .dp-calendar-day {
    box-sizing: border-box;
    width: 41px !important;
    height: 30px;
    cursor: pointer;
}

dp-day-time-calendar {
    display: block !important;
}

dp-month-calendar {
    display: block !important;
    div .dp-calendar-wrapper {
        display: block !important;
    }
    div .dp-months-row {
        display: flex !important;
        justify-content: space-evenly !important;;
    }
}
